import React from "react";
import styled from "styled-components";
import iconImage from "../../shared/images/icons/ICON_BLACK_TRANSPARENT.png";
import middleIconImage from "../../shared/images/white_no_border/FORKED_TECH_CO.PNG";

const AppContainer = styled.div`
  background-color: white;
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const IconImage = styled.img`
  width: 40px;
  height: 40px;
`;

const MiddleIconImage = styled.img`
  width: 300px;
  height: auto;
`;

const MiddleText = styled.div`
  font-size: 12px;
  text-align: center;
`;

const FooterText = styled.div`
  font-size: 12px;
  text-align: center;
  position: absolute;
  bottom: 20px;
`;

function App() {
  return (
    <AppContainer>
      <IconImage src={iconImage} alt="Icon" />
      <br />
      <MiddleIconImage src={middleIconImage} alt="Middle Icon" />
      <MiddleText>
        <br />
        Analytics and website development for crypto
        <br />
        au: Melbourne, asia: Singapore
      </MiddleText>

      <MiddleText>
        <br />
        <br />
        About  |  Portfolio  |  Store  |  Contact
      </MiddleText>

      <FooterText>
        <br />
        <br />
        FORKED DIGITAL PTY LTD - ACN 660 719 004
      </FooterText>
      {/* Your website content goes here */}
    </AppContainer>
  );
}

export default App;
